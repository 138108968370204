











































































































































import Vue from 'vue'
import VSelect from '../../components/BaseComponents/VSelect/VSelect.vue'
import BasePagination from '../../components/BaseComponents/BasePagination/BasePagination.vue'
import Preloader from '@/components/BasePagePreloader.vue'
import Button from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
  name: 'RuleLogs',
  components: {
    // TabSwitcher,
    VSelect,
    BasePagination,
    Preloader,
    Button
  },
  props: {
    service: {
      required: true,
      type: String
    },
    ruleId: {
      required: true
    }
  },
  data () {
    return {
      menu: [
        { id: 0, title: 'All checkings' },
        { id: 1, title: 'Triggered and errors' }
      ],
      activeTab: { id: 0, title: 'All checkings' },
      selected: 'All checkings',
      page: 1,
      showPages: 10,
      componentKey: 0,
      adAccountId: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('adAccounts', ['getById']),
    ...mapGetters('rules', ['getRuleById']),
    ...mapGetters('adService', ['getService']),
    currency () {
      return this.getById(this.adAccountId)?.currency
    },
    rulesName () {
      return this.getRuleById(this.ruleId)?.name
    }
  },
  watch: {
    showPages (showPages) {
      const total = this.$store.state.rules.log.meta.pagination.total_pages
      if (showPages > total) {
        this.switchPage(1, this.showPages)
      }
    }
  },
  mounted () {
    this.loading = true
    this.setService(this.service)
    const promises = [
      this.loadAccounts({ search: { service: this.getService, target: 'ads_management' } }),
      this.getRuleLogsFromServer(
        {
          params: {
            ruleId: this.ruleId,
            page: this.page,
            showPages: this.showPages
          }
        }
      ),
      this.getRuleFromServer({ ruleId: this.ruleId }).then(() => {
        this.adAccountId = this.getRuleById(this.ruleId)
          .ad_account_id
      }).catch((error) => {
        if (error?.response?.status === 404) {
          this.$router.push({ name: '404' })
        }
      })
    ]

    Promise.allSettled(promises).then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('rules', ['getRuleLogsFromServer']),
    ...mapActions('rules', ['getRuleFromServer']),
    ...mapActions('adService', ['setService']),
    ...mapActions('adAccounts', ['loadAccounts']),
    changeValue (arg) {
      this.selected = arg
    },
    async switchPage (pageNum: string, showPages) {
      this.showPages = showPages
      this.page = pageNum
      this.getRuleLogsFromServer({
        params: {
          ruleId: this.ruleId,
          page: this.page,
          showPages: showPages
        }
      })
    },
    getMetrics (metrics) {
      return metrics.map(metric => {
        return `${metric.name}: ${metric.value} ${this.getValue(metric)}`
      })
    },
    getValue (metric) {
      if (metric.type === 'percent') return '%'
      if (metric.type === 'money') {
        if (this.currency) return this.currency
        else return '$'
      }
      return ''
    },
    getActionName (name = '') {
      name = name.toLowerCase().replace(/_/gi, ' ')
      return `${name[0].toUpperCase() + name.slice(1)}`
    },
    getApplied (applied) {
      const appliedArr = applied.split(' ')
      appliedArr.map((item, index) => {
        if (item === '=>') {
          appliedArr.splice(index, 1, '➔')
        }
        return item
      })
      return appliedArr.join(' ')
    }
  }
})
